.logoContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.logo {
    width: 50%;
}
